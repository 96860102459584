export const questions = [
    {
        id: 1,
        question: "How long do you typically respond to requests?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },

    {
        id: 2,
        question: "Why would’t I just hire my own team? ",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },

    {
        id: 3,
        question: "What if I’m not happy with the quality of work?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },



    {
        id: 4,
        question: "What if I only have a single request?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },

    {
        id: 5,
        question: "What if I’m not happy with the level of revisions?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },

    {
        id: 6,
        question: "How does the pause feature work?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },

    {
        id: 7,
        question: "How do I cancel my subscription?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },

    {
        id: 8,
        question: "What if I dont like the design?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },
    {
        id: 9,
        question: "How do you gain access to my account?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },

    {
        id: 10,
        question: "How to I submit a ticket? ",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },
    {
        id: 11,
        question: "Do I need to give you access to my account?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },
    {
        id: 12,
        question: "Are there refunds available if Im not happy with the service?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },
      {
        id: 13,
        question: "What are the risks of giving access to my account?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },
      {
        id: 14,
        question: "Why wouldn’t I just find someone on Upwork or Fiverr?",
        answer: `Each Audit is jam packed with examples, mockups, ideas and proven tips you & your team can execute and begin testing right away, as well as bigger future considerations. `,
        answer2: `We can provide a list of trusted partners to help execute recommendations if needed.`,

    },
   
]